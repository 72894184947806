import React, {Suspense} from "react"
import HomeHeroCarousel from "./HomeHeroCarousel"
import FeaturesBlock from "./FeaturesBlock"
import HeaderBlock from "./HeaderBlock"
import Fallback from "../Core/Fallback"


const TextImageBlock = React.lazy(() => import("./TextImageBlock"))
const PressBlock = React.lazy(() => import("./PressBlock"))
const ReviewCarousel = React.lazy(() => import("./ReviewCarousel"))
const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))
const ProductsCallout = React.lazy(() => import("./ProductsCallout"))
const BlogCallout = React.lazy(() => import("./BlogCallout"))
const SubscribeBanner = React.lazy(() => import("./SubscribeBanner"))
const Instafeed = React.lazy(() => import("./Instafeed"))

const PageContents = ({ blocks }) => {

  const pageBlocks = []
  let features = []
  let press = []
  const isSSR = typeof window === "undefined"


  blocks.forEach((block, index) => {
    const { __typename: type, title } = block

    if (type === "ContentfulHeroCarousel") {
      pageBlocks.push(<HomeHeroCarousel {...block} key={index} />)
    }

    if(type === "ContentfulSubscribeBanner"){
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><SubscribeBanner {...block} key={index}/></Suspense>}</React.Fragment>)
    }

    if (type === "ContentfulTitleTextBlock") {
      pageBlocks.push(<HeaderBlock {...block} key={index} />)
    }

    if (type === "ContentfulFeature") {
      features.push(block)
    }

    if (
      features.length !== 0 &&
      index ===
        blocks.map((el) => el.__typename).lastIndexOf("ContentfulFeature")
    ) {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback />}>(<FeaturesBlock features={features} /></Suspense>}</React.Fragment>)
    }


    if (type === "ContentfulTextImageBlock") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><TextImageBlock {...block} /></Suspense>}</React.Fragment>)
    }

    if (type === "ContentfulPress") {
      press.push(block)
    }
    if (
      press.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulPress")
    ) {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><PressBlock press={press} index={index} /></Suspense>}</React.Fragment>)
    }

    if (type === "ContentfulReviewsCarousel") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><ReviewCarousel {...block}  /></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulQuizCallout") {
      pageBlocks.push(<React.Fragment  key={index}>{!isSSR && <Suspense fallback={<Fallback/>}> <QuizCtaBlock {...block} /></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulProductsCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><ProductsCallout {...block} index={index} /></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulBlogCallout") {
      pageBlocks.push(<React.Fragment key={index} >{!isSSR && <Suspense fallback={<Fallback/>}><BlogCallout {...block}/></Suspense>}</React.Fragment>)
    }
    if (type === "ContentfulLinkList" && title === "Instafeed Slider") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><Instafeed {...block} /></Suspense>}</React.Fragment>)
    }
  })


  const arrayMove = (arr, fromIndex, toIndex) => {
    const elem = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, elem);
  }
  arrayMove(pageBlocks, 5, 1);
  arrayMove(pageBlocks, 2, 5);
  arrayMove(pageBlocks, 2, 5);



  return (
    <React.Fragment>{pageBlocks}</React.Fragment>
    )
}

export default PageContents

import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import ResponsiveImage from "../Core/Image/ResponsiveImage"
import Video from "../Core/Video"
import { Link, navigate } from "gatsby"
import colors from "../../styles/colors"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ButtonStyleDark, TextButtonStyle } from "../Core/Buttons/Styles"
import { cvgTrack } from "../../services/tracking/converge"

const MediaLink = styled(Link)`
  height: fit-content;
  width: fit-content;
  display: block;
`

const HeroImage = styled.div`
  height: ${(props) => (props.clickableHero ? "71.59vw" : "300px")};
  display: flex;

  @media (min-width: ${breakpoints.md}) {
    height: ${(props) => (props.clickableHero ? "71.5vw" : "450px")};
  }

  @media (min-width: ${breakpoints.lg}) {
    height: ${(props) =>
      props.clickableHero ? "calc(71.5vw + 80px)" : "calc(100vh - 110px)"};
  }

  @media (min-width: ${breakpoints.lgr}) {
    height: ${(props) =>
      props.clickableHero ? "calc(37.84vw + 80px)" : "calc(100vh - 110px)"};
  }
`

const HeroContent = styled.div`
  padding: 0 30px;
  margin-bottom: ${(props) => props.margin && "30px"};
  @media (min-width: ${breakpoints.md}) {
    box-sizing: border-box;
    padding: 0 55px;
  }
`

const HeaderContainer = styled.div`
  margin: 30px 0 15px;

  @media (min-width: ${breakpoints.md}) {
    margin: ${(props) => (props.margin ? "0 0 40px" : "0 0 20px")};
    max-width: 52vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    max-width: 670px;
  }
`

const Header = styled.h1`
  ${fonts.garamondRegular};
  font-size: 35px;
  line-height: 1.14;
  text-align: center;
  letter-spacing: -0.18px;
  @media (min-width: ${breakpoints.md}) {
    text-align: ${(props) => (props.right ? "right" : "left")};
    margin: ${(props) => (props.margin ? "0 0 40px" : "0 0 20px")};
    font-size: 46px;
    line-height: 1.1;
    letter-spacing: normal;
    color: ${(props) => (props.color ? props.color : colors.charcoal)};
  }
  @media (min-width: ${breakpoints.md}) {
    font-size: 35px;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 60px;
  }
`

const CTAs = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 27px;
  text-align: center;
  :last-of-type {
    margin-bottom: 0;
  }

  a {
    max-width: 280px;
    width: 100% !important;
    background: ${(props) => props.mobileBackground && props.mobileBackground};
    border-color: ${(props) => props.mobileBorder && props.mobileBorder};
    color: ${(props) => props.mobileColor && props.mobileColor};

    span {
      border-color: ${(props) => props.mobileBorder && props.mobileBorder} !important;
    }
  }

  a:hover {
    background: ${(props) => props.hoverBcg && props.hoverBcg};
    border-color: ${(props) => props.hoverBorder && props.hoverBorder};
    color: ${(props) => props.hoverColor && props.hoverColor};

    span {
      border-color: ${(props) => props.hoverBorder && props.hoverBorder} !important;
    }
  }
  @media (max-width: ${breakpoints.md}) {

  }
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    :last-of-type {
      margin-bottom: 0;
    }

    a {
      margin-left: 0;
      max-width: 280px;
      background: ${(props) => props.background && props.background};
      border-color: ${(props) => props.border && props.border};
      color: ${(props) => props.color && props.color};

      span {
        border-color: ${(props) => props.border && props.border} !important;
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
    margin-right: 20px;
    min-width: 230px;
    :last-of-type {
      margin-right: 0;
    }
  }
`

const Intro = styled.div`
  ${(props) => (props.font ? fonts[props.font] : fonts.sofiaPro)};
  max-width: 420px;
  margin: auto;
  min-width: 100%;
  margin-bottom: 30px;
  text-align: center;

  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.33;
  p {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-right: ${(props) => (props.right ? "auto" : "0")};
    margin-left: ${(props) => (props.right ? "0" : "auto")};
    margin-bottom: 40px;
    text-align: ${(props) => (props.right ? "right" : "left")};
    color: ${(props) => (props.color ? props.color : colors.charcoal)};
    font-size: 20px;
    p {
      max-width: 500px;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
  }
`

const StyledCol = styled(Col)`
  @media (min-width: ${breakpoints.md}) {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.right ? "flex-end" : "flex-start")};
    padding-top: 30px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 130px;
  }
`
const HeroTicker = styled.div`
  ${fonts.sofiaProBold}
  font-weight:700;
  text-align: center;
  width: 100vw;
  padding: 15px;
  margin-top: -1px;
  color: ${colors.white};
  background-color: ${colors.charcoalLight};
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const TextButton = styled.a`
  ${TextButtonStyle}
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-thickness: 3px;
`

const ButtonDark = styled.a`
  ${ButtonStyleDark}
`

const Hero = ({
  media,
  imageMobile,
  header,
  ctas,
  textPositionMobile,
  isMediaCta,
  mediaUrl,
  textPositionDesktop,
  intro,
  headerFont,
  introFont,
  headerTextColor,
  introTextColor,
  ctasHoverBackground,
  ctasHoverColor,
  ctasHoverBorder,
  mobileCtaBackgroundColor,
  mobileCtaBorderColor,
  mobileCtaTextColor,
  heroTicker,
  tickerContent,
  clickableHero
}) => {

  const {
    file: { contentType, url },
  } = media
  const isVideo = contentType === "video/mp4"
  const mediaLink = isMediaCta && mediaUrl
  const headerFontFamily =
    headerFont && headerFont !== "Auto" ? headerFont : "garamondRegular"
  const introFontFamily =
    introFont && introFont !== "Auto" ? introFont : "sofiaPro"

  const mediaContent = () => (
    <HeroImage clickableHero={clickableHero ? 1 : 0}>
      {isVideo ? (
        <Video
          src={url}
          imageMobile={imageMobile}
          alt={header || ""}
          loading={"eager"}
        />
      ) : (
        <ResponsiveImage
          imageDesktop={media}
          imageMobile={imageMobile}
          alt={header || ""}
          loading={"eager"}
        />
      )}
    </HeroImage>
  )

  const isTextRight = textPositionDesktop === false

  const handleButtonClick = (event, label) => {
    event.preventDefault()

    cvgTrack({
      eventName: 'Hero button click',
      properties: {
        label: label
      }
    })

    navigate(event.target.href)
  }

  const handleLinkClick = (event, label) => {
    event.preventDefault()

    cvgTrack({
      eventName: 'Hero link click',
      properties: {
        label: label
      }
    })

    navigate(event.target.href)
  }

  return (
    <Row>
      <Col>
        {mediaLink ? (
          <MediaLink to={mediaUrl}>{mediaContent()}</MediaLink>
        ) : (
          mediaContent()
        )}
      </Col>
      {heroTicker && <HeroTicker>{tickerContent}</HeroTicker>}
      <StyledCol
        xsOrder={textPositionMobile ? "first" : "last"}
        right={isTextRight ? 1 : 0}
      >
        <HeroContent margin={textPositionMobile ? 1 : 0}>
          {header && (
            <HeaderContainer margin={intro ? 0 : 1}>
              <Header
                dangerouslySetInnerHTML={{ __html: header }}
                right={isTextRight ? 1 : 0}
                margin={intro ? 0 : 1}
                color={headerTextColor}
                font={headerFontFamily}
              />
            </HeaderContainer>
          )}
          {intro && (
            <Intro
              right={isTextRight ? 1 : 0}
              color={introTextColor}
              font={introFontFamily}
            >
              {renderRichText(intro)}
            </Intro>
          )}
          {ctas && ctas.length > 0 && (
            <CTAs right={isTextRight ? 1 : 0}>
              {ctas.map((cta, index) => {
                const isEven = index % 2 === 0
                const {
                  label,
                  url,
                  ctaBackgroundColor,
                  ctaBorderColor,
                  ctaTextColor,
                  transparentBackground,
                  mobileCtaBackgroundColor,
                  mobileCtaBorderColor,
                  mobileCtaTextColor,
                } = cta
                const background =
                  transparentBackground === "Yes"
                    ? "transparent"
                    : ctaBackgroundColor
                const mobileBackground = mobileCtaBackgroundColor
                  ? ctaBackgroundColor
                  : background
                const mobileColor = mobileCtaTextColor
                  ? mobileCtaTextColor
                  : ctaTextColor
                const mobileBorder = mobileCtaBorderColor
                  ? mobileCtaBorderColor
                  : ctaBorderColor


                return isEven ? (
                  <ButtonContainer
                    key={index}
                    background={background}
                    color={ctaTextColor}
                    border={ctaBorderColor}
                    hoverBcg={ctasHoverBackground}
                    hoverColor={ctasHoverColor}
                    hoverBorder={ctasHoverBorder}
                    mobileBackground={mobileBackground}
                    mobileBorder={mobileBorder}
                    mobileColor={mobileColor}
                  >
                    <ButtonDark href={url} onClick={(e) => handleButtonClick(e, label)}>{label}</ButtonDark>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer
                    key={index}
                    background={background}
                    color={ctaTextColor}
                    border={ctaBorderColor}
                    hoverBcg={ctasHoverBackground}
                    hoverColor={ctasHoverColor}
                    hoverBorder={ctasHoverBorder}
                    mobileBackground={mobileBackground}
                    mobileBorder={mobileCtaBorderColor ? mobileCtaBorderColor : colors.charcoal}
                    mobileColor={mobileCtaTextColor ? mobileCtaTextColor : colors.charcoal}
                  >
                    <TextButton href={url} onClick={(e) => handleLinkClick(e, label)}>{label}</TextButton>
                  </ButtonContainer>
                )
              })}
            </CTAs>
          )}
        </HeroContent>
      </StyledCol>
    </Row>
  )
}

export default Hero

import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import PageContents from "../components/Homepage/PageContents"
import { getIndexSchema } from "../services/core/schema"

const IndexPage = ({ location, data, pageContext }) => {
  const path = location.pathname
  const { page, products, content } = data

  const reviewsData = pageContext.reviewsData

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  const allProducts = products.nodes.filter(product => product.handle !== "scalp-brush-white")
  const allProductContent = content.nodes

  let schemaInput = []

  allProducts.forEach(product => {
    const {handle, featuredImage, title, priceRangeV2, shopifyId} = product
    const ratingData = reviewsData.find(dataItem => dataItem.id === shopifyId.split("/").pop())
    const productContent = allProductContent.find(entry => entry.slug === handle)

    if (productContent && ratingData?.reviewCount && ratingData.reviewCount > 0) {
      schemaInput.push(
        {
          title: title, 
          imageSrc: featuredImage?.src, 
          description: productContent?.meta_description?.meta_description, 
          price: priceRangeV2?.minVariantPrice?.amount, 
          inStock: true, 
          slug: handle,
          rating: ratingData?.rating,
          reviewCount: ratingData?.reviewCount
        }
      )
    }
  })

  const openCookiePreferences = async () => {
    // Give OneTrust time to load
    await new Promise(resolve => setTimeout(resolve, 3000))
    if (typeof window !== 'undefined') {
      const currentUrl = new URL(window.location.href)
      const cookiePreferencesOpen = currentUrl.search.replace("?cpCenter=", "")
      if (cookiePreferencesOpen === "true" && typeof window.OneTrust !== "undefined") {
        window.OneTrust.ToggleInfoDisplay()
      }
    }
  }

  useEffect(() => {
    openCookiePreferences()
  }, [])

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  const schema = getIndexSchema(schemaInput)

  return (
    <Layout
      schema={schema}
    >
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={true}
        image={sharingImage}
      />
      <PageContents blocks={blocks} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomepageQuery {
    page: contentfulPage(slug: { eq: "homepage" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      blocks {
        ... heroCarouselFragment
        ... titleTextFragment
        ... featureFragment
        ... textImageFragment
        ... productsCalloutFragment
        ... pressFragment
        ... carouselFragment
        ... quizCalloutFragment
        ... blogCalloutFragment
        ... homepageLinkedListFragment
        ... homepageSubscribeBannerFragment
      }
    }
    products: allShopifyProduct {
      nodes {
        handle
        shopifyId
        description
        featuredImage {
          src
        }
        title
        priceRangeV2 {
          minVariantPrice {
            amount
          }
        }
      }
    }
    content: allContentfulProduct {
      nodes {
        slug
        meta_description {
          meta_description
        }
      }
    }
  }
`